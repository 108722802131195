// import { auth, googleProvider } from '../../services/Firebase'
import { auth, googleProvider } from "../../services/Firebase";
import {  signInWithPopup, signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";



export const signInWithGoogle = async () => {
    try {
        await signInWithPopup(auth, googleProvider);
    } catch (err) {
        console.error(err);
    }
};


export const logOut = async () => {
    try {
        await signOut(auth);
    } catch (err) {
        console.error(err);
    }
};

//1.

//2.
export const AuthContext = React.createContext();

//3.
export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const signInWithGoogle = async () => {
        try {
            const data = await signInWithPopup(auth, googleProvider);
            localStorage.setItem('user', data)
            setUser(data?.displayName);
            navigate('/tools')
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        const loggedInUser = localStorage.getItem("user");
        if (loggedInUser) {
            const foundUser = JSON. stringify(loggedInUser);
            setUser(foundUser.displayName);
        }
    }, []);


    const [user, setUser] = useState(null);


    return (
        <AuthContext.Provider value={{ user, signInWithGoogle }}>{children}</AuthContext.Provider>
    );
};