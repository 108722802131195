import db from "./Firebase";
// import { ref, set } from "firebase/database";


export default {
    toolsFirstBatch: async function () {
        try {
            const data = await db
                .collection("tools")
                .orderBy("name", "desc")
                .limit(100)
                .get();

            let tools = [];
            let lastKey = "";
            data.forEach((doc) => {
                tools.push({
                    postId: doc.data().name,
                    ...doc.data()
                });
                lastKey = doc.data().name;
            });

            return { tools, lastKey };
        } catch (e) {
            console.log(e);
        }
    },

    /**
     * this function will be fired each time the user click on 'More tools' button,
     * it receive key of last post in previous batch, then fetch next 5 tools
     * starting after last fetched post.  
     */
    toolsNextBatch: async (key) => {
        try {
            const data = await db
                .collection("tools")
                .orderBy("name", "desc")
                .startAfter(key)
                .limit(100)
                .get();

            let tools = [];
            let lastKey = "";
            data.forEach((doc) => {
                tools.push({
                    postId: doc.data().name,
                    ...doc.data()
                });
                lastKey = doc.data().name;
            });
            return { tools, lastKey };
        } catch (e) {
            console.log(e);
        }
    },

    writeUserData: async (data) => {
        db.collection("tools").add(data).catch(alert);

    }

};
