import React, { useContext, useRef, useState } from 'react'
import "./navbar.css"
import { RiMenu3Line, RiCloseLine } from "react-icons/ri"
import { useLocation, useNavigate } from 'react-router'
import { AuthContext } from '../login/login'

const Menu = ({ closeToggle }) => {
  const navigate = useNavigate();
  const location = useLocation()
  return <>
    <p onClick={() => {
      navigate(`/`)
      closeToggle()
    }} className={location.pathname === '/' && 'active'}><a>Home</a></p>
    <p onClick={() => {
      navigate(`/tools`)
      closeToggle()
    }} className={location.pathname === '/tools' && 'active'}><a>AI Tools</a></p>
  </>
}

const Navbar = () => {

  const catMenu = useRef(null)
  const [toggleMenu, setToggleMenu] = useState(false)
  const closeOpenMenus = (e) => {
    if (catMenu.current && toggleMenu && !catMenu.current.contains(e.target)) {
      setToggleMenu(false)
    }
  }
  const { user, signInWithGoogle } = useContext(AuthContext)
  document.addEventListener('mousedown', closeOpenMenus)
  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          {/* <img src={logo} alt="GPT3 Logo" /> */}
        </div>
        {user !== null && <div className='links'></div>}
        <div className="gpt3__navbar-links_container">
          <Menu />
        </div>
        <div className="gpt3__navbar-wrapper">

          <div className="gpt3__navbar-sign">
            {user === null && <button onClick={signInWithGoogle} type='button'>Sign in</button>}
          </div>
          {/* Responsive part for mobiles devices */}
          {/* hamburger menu */}
          <div className="gpt3__navbar-menu">
            {toggleMenu
              ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
              : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
            }
            {toggleMenu &&
              <div ref={catMenu} className="gpt3__navbar-menu_container scale-up-center">
                {<div className="gpt3__navbar-menu_container-links-sign">
                  <button type='button' onClick={signInWithGoogle}>Sign in</button>
                </div>}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar