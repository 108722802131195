import React from 'react'
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { Article } from '../../components'
import SearchIcon from '@mui/icons-material/Search';
import Tools from '../../services/Tools';
import "../../containers/blog/blog.css"
import DropDownSelect from './autoComplete';
import { Stack } from '@mui/system';
import { TextField } from '@mui/material';

const cateogoryList = [
    "Chat",
    "Productivity",
    "Marketing",
    "Advertising",
    "Copywriting",
    "Generative Art",
    "AI Detection",
    "Coding",
    "Audio",
    "Text-to-Voice",
    "Text-to-voice",
    "Music",
    "Social Media",
    "Image Editing",
    "Video Generation",
    "Education",
    "Text-to-Video",
    "SEO",
    "Video Editing",
    "Generative Video",
    "Automation",
    "Podcasting",
    "Recruitment",
    "Graphic Design",
    "Gaming",
    "Business Intelligence",
    "Translation",
    "Nocode"
]


const ToolsList = () => {
    const [tools, settools] = useState([]);
    const [ratings, setRatings] = useState(null);
    const [category, setCategory] = useState(null);
    const [filteredTools, setFilteredTools] = useState([]);
    const [lastKey, setLastKey] = useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filterValue, setFilterValue] = useState('');
    const [nexttools_loading, setNexttoolsLoading] = useState(false);

    const filterBySearch = () => {
        const query = filterValue;
        var updatedList = [...tools];
        updatedList = updatedList.filter((item) => {
            return item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        });
        setFilteredTools(updatedList);
    };

    const applyFilters = () => {
        var updatedList = [...tools];
        if (category === null && ratings === null) return setFilteredTools(tools);
        updatedList = updatedList.filter((item) => {
            if (category !== null && ratings !== null) return item.ratings === ratings && item.category === category;
            else if (ratings !== null) return item.ratings === ratings;
            else if (category !== null) return item.category === category;
        });
        setFilteredTools(updatedList);
        handleClose();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => setFilteredTools(tools), [tools])

    useEffect(() => {
        Tools.toolsFirstBatch()
            .then((res) => {
                settools(res.tools);
                setLastKey(res.lastKey);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const fetchMoretools = (key) => {
        if (key.length > 0) {
            setNexttoolsLoading(true);
            Tools.toolsNextBatch(key)
                .then((res) => {
                    setLastKey(res.lastKey);
                    settools(prevTools => [...prevTools, ...res.tools]);
                    setNexttoolsLoading(false);
                })
                .catch((err) => {
                    setNexttoolsLoading(false);
                });
        }
    };

    const handleReset = () => {
        setFilterValue('');
        setCategory(null);
        setRatings(null);
        setFilteredTools(tools)
    }

    return (
        <div className="gpt3__blog section__padding">
            <div className="gpt3__blog-heading">
                <h1 className="gradient__text">Hot AI Tools</h1>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="gpt3__header-content__input" style={{ width: '90%', margin: '0px 1rem 0px 0px' }}>
                    <input type="email" placeholder="Search Tools" value={filterValue} onChange={(e) => setFilterValue(e?.target?.value)} />
                    <button type="button" style={{ maxWidth: '80px', paddingTop: '0.2rem' }} onClick={filterBySearch}>{<SearchIcon></SearchIcon>}</button>
                </div>
                <button aria-describedby={id} style={{ minHeight: '40px', marginRight: '10px' }} variant="contained" className='common-btn' onClick={handleReset}>
                    Reset
                </button>
                <div>
                    <button aria-describedby={id} style={{ minHeight: '40px' }} variant="contained" className='common-btn' onClick={handleClick}>
                        Filters
                    </button>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        keepMounted={true}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Stack sx={{ p: 2 }} className=''>
                            <DropDownSelect optionsList={cateogoryList} id={'category-select'} onChange={(e, value) => {
                                console.log(value)
                                setCategory(value)
                            }} label={'Choose category to filter'} />
                            <TextField
                                type={'number'}
                                style={{ color: 'white', marginTop: 2 }}
                                id="ratings"
                                label="Ratings"
                                value={ratings}
                                onChange={(e) => {
                                    setRatings(e?.target?.value)
                                }}
                            />
                            <button className='common-btn' style={{ maxWidth: '100%', marginTop: '15px' }} onClick={applyFilters}>Apply Filters</button>
                        </Stack>
                    </Popover>
                </div>
            </div>
            <div className="" style={{ flexDirection: 'row', display: 'flex', overflow: 'hidden', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '1.5rem' }}>
                {filteredTools.map(({ description, image_url, name, ratings, category, browsingUrl }) => {
                    return (
                        <div onClick={() => window.location.replace(browsingUrl)}>
                            <Article imgUrl={image_url} date={description} title={name} rating={ratings} category={category} />
                        </div>)
                })}
            </div>
            <div style={{ alignSelf: 'end', display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '25px', margin: 'auto' }}>
                <span style={{ color: 'white' }}>Showing {filteredTools.length} tools</span>
                {<button className='common-btn' style={{ color: 'white', cursor: 'pointer' , marginTop:'15px',maxWidth : 'max-content' }} onClick={() => { fetchMoretools(lastKey) }}>Load More</button >}
            </div>
        </div>
    )
}

export default ToolsList