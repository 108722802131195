import firebase from "firebase/compat/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
import "firebase/compat/firestore";

const firebaseConfig = {
  projectId: "established-edde8",
  apiKey: "AIzaSyBaCzFL2q8RfcK_qrVS3GBFKJkMrJAiumc",
  authDomain :'established-edde8.firebaseapp.com'
};

export const app = firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export default db;