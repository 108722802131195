import React from 'react'
import "./App.css"
import { Blog, Features, Footer, Header, Possibility, WhatGPT3 } from "./containers";
import { CTA, Brand, Navbar } from "./components";
import { Routes, Route } from "react-router-dom"
import ToolsList from './components/tools/toolsPage';

const CommonApp = <>
  <div className="gradient__bg">
    <Header />
  </div>
  <Brand />
  <WhatGPT3 />
  <Features />
  <Possibility />
  <CTA />
  <Footer />
</>

const ToolsListApp = <>
  <div className="gradient__bg">
    <ToolsList />
  </div>
</>

function App() {
  return (
    <div className="App">
      <div className="gradient__bg">
        <Navbar />
      </div>
      <Routes>
        <Route path="/" element={CommonApp} />
        <Route path="tools" element={ToolsListApp} />
      </Routes>
    </div>
  );
}

export default App;
