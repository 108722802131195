import React, { useState } from 'react'
import StarRatings from 'react-star-ratings';
import { Icon } from '@iconify/react';
import "./article.css"
import image from "../../assets/ai.png"
import { Badge } from '@mui/material';

// Parameter Destructuring
const Article = ({ imgUrl, date, title, rating, category }) => {
  const [imgSrc, setImgSrc] = useState(imgUrl);
  return (
    <div className='tool-box'>
      <div className='browse-icon' style={{ float: 'right', marginBottom: '10px', marginRight: '10px' }}>
        <Icon icon="icon-park-outline:browser-chrome" color="#ff4820" />
      </div>
      <div className="gpt3__blog-container_article">
        <div className="gpt3__blog-container_article-image">
          <img src={imgSrc} onError={() => setImgSrc(image)} />
        </div>
        <div className="gpt3__blog-container_article-content">
          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
            <h3 style={{width :'60%'}}>{title}</h3>
            <div>
              <Badge badgeContent={<p style={{ width: 'max-content' }}>{category}</p>} color="primary" style={{ marginRight: '40px' }} />
            </div>
          </div>
          <div className='description' style={{ height: '140px', overflowY: 'auto' }}>
            <p>{date}</p>
          </div>
        </div>
        <div style={{ marginTop: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
          <div>
            <StarRatings
              isAggregateRating={true}
              rating={parseInt(rating) || 4}
              starDimension="20px"
              starSpacing="7px"
              starRatedColor="orange"
              numberOfStars={5}
              name='rating'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Article;