import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function DropDownSelect({ id, optionsList, onChange, label }) {
    return (
        <Autocomplete
            id={id}
            sx={{ width: 300, color: 'white', mb: 2 , mt:1 }}
            options={optionsList}
            onChange={onChange}
            autoHighlight
            getOptionLabel={(option) => option}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0, } }} {...props}>
                    {option}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    sx={{ color: 'white' }}
                    {...params}
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                    }}
                />
            )}
        />
    );
}